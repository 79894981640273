import React from 'react'
import { v4 } from 'uuid'
import {
  Button,
  Box,
  HStack,
  Tag,
  TagLeftIcon,
  TagLabel,
  Flex,
  VStack,
  List,
  ListItem,
  UnorderedList,
  OrderedList,
} from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GiCutDiamond, GiBeamsAura, GiAmericanShield } from 'react-icons/gi'
import { useTheme } from '@emotion/react'
import { format } from 'date-fns'

import LandingPage, {
  Header,
  Gallery,
  Features,
  Faqs,
  Video,
  Testimonies,
} from '../components/landingPage'

import {
  H1,
  P,
  Image,
  WhatsappButton,
  Article,
  Hero,
  HeroBody,
  HeroTitle,
  HeroSubtitle,
  H3,
  ColVideo,
  ColInfo,
  RowContent,
  Section,
  FeatureList,
  FeatureItem,
  ColImage,
} from '../ui'
import FloatingBook from '../components/floatingBook'

import Layout from '../components/layout'
import DividerVertical from '../components/dividerVertical'
import { ColTitle, PostTitle, PreTitle, SectionTitle, Title } from '../components/texts'
import ButtonBook from '../components/buttonBook'

import heroPreview from '../images/landing/jets/gallery/6.jpg'
import youtubePreview from '../images/landing/jets/gallery/6.jpg'

import forbesLogo from '../images/index/forbes.jpg'

function IndexPage({ data }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Layout disableWhatsapp title={t('landing:jets.title')}>
      <Hero
        justifyContent="center"
        minHeight={{ base: '50vh', md: '60vh' }}
        image={
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: 0.85 }}
          />
        }
      />

      <Article>
        <Section titleAlign="center" justifyContent="center">
          <DividerVertical />
          <Title color="secondary.800">Aviso de Privacidad</Title>
          <P textAlign="center">Nuestra prioridad es proteger tu información</P>
          <DividerVertical />
        </Section>

        <Section spacing={10}>
          <P>
            En Global Jet Set estamos comprometidos con salvaguardar la privacidad de sus datos
            personales, este aviso es aplicable a <b>GWBG MANAGEMENT, S.A. DE C.V.</b> (en adelante
            Global Jet Set), en consecuencia y de conformidad con lo establecido en el Artículo 17,
            fracción II, de la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares, le informamos que Global Jet Set tratará los datos personales que recabe
            de Usted en los términos del presente aviso de privacidad:
          </P>
          <P>
            <b>DOMICILIO</b>
          </P>
          <P>
            Para efectos del presente aviso de privacidad de Global Jet Set, señalan como domicilio
            el ubicado en Tlaxcala número 8, interior B, Col. Roma Sur, C.P. 06760, Delegación
            Cuauhtémoc, Ciudad de México.
          </P>
          <P>
            <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS</b>
          </P>
          <P>
            Los datos personales que Usted proporcione a Global Jet Set serán tratados
            exclusivamente para la finalidad descrita en este aviso de privacidad y se les tratará
            de forma confidencial y exclusiva para el fin que hubiera sido recabado. Los datos
            personales que podrán ser recabados conforme a este aviso de privacidad son los
            siguientes: nombre completo, domicilio completo, dirección fiscal, teléfono móvil y/o
            fijo, correo electrónico, nacionalidad, país de residencia, estado civil, identificación
            oficial, RFC, CURP, formato de alta en hacienda; y los siguientes datos financieros:
            instituciones bancarias a las que pertenezca, números de cuenta, números de cuenta
            CLABE, entre otros. En relación con los datos personales sensibles que se mencionan en
            el párrafo anterior, Global Jet Set se compromete a que el tratamiento será el
            absolutamente indispensable para las finalidades mencionadas y a tratarlos bajo las
            medidas se seguridad adecuadas para proteger la confidencialidad.
          </P>
          <P>
            <b>FINALIDAD DE LOS TRATAMIENTOS DE SUS DATOS PERSONALES</b>
          </P>
          <P>
            Le informamos que Global Jet Set, de acuerdo con los términos del presente aviso, podrá
            utilizar sus datos para las siguientes finalidades las cuales son necesarias para
            concretar nuestra relación con Usted, así como para atender los servicios y/o
            solicitudes que requiera:
          </P>
          <UnorderedList>
            <ListItem>
              <P>Llenado de formularios</P>
            </ListItem>
            <ListItem>
              <P>Base de datos</P>
            </ListItem>
            <ListItem>
              <P>Estadística</P>
            </ListItem>
          </UnorderedList>
          <P>
            <b>
              DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN DE DATOS PERSONALES “ARCO”
            </b>
          </P>
          <P>
            De forma que, al momento en que Usted navega por nuestro sitio web
            (globaljetsetmexico.com) ha leído y acepta nuestros términos, Usted tendrá derecho de:
            (a) acceder a sus datos personales en nuestro poder y conocer los detalles del
            tratamiento de los mismos, (b) rectificarlos en caso de ser inexactos o incompletos, (c)
            cancelarlos cuando considere que no se requieren para alguna de las finalidades
            señaladas en el presente aviso de privacidad, estén siendo utilizados para finalidades
            no consentidas o haya finalizado la relación contractual o de servicio, u (d) oponerse
            al tratamiento de los mismos para fines específicos, según lo diga la ley, en adelante
            “Derechos ARCO”. Global Jet Set ha designado a un encargado de datos personales, por lo
            tanto Usted podrá limitar el uso o divulgación de sus datos personales mediante
            comunicación dirigida al siguiente correo electrónico:
            alejandrohernandez@globaljetsetmexico.com. El encargado de datos personales responderá
            su Solicitud de Ejercicio de Derechos ARCO y los motivos de su decisión mediante correo
            electrónico o presencial en las oficinas corporativas en un plazo máximo de 20 días
            hábiles contados desde el día en que se haya recibido su Solicitud de Ejercicio de
            Derechos ARCO. Para ser procesada su petición, ésta deberá incluir todos y cada uno de
            los requisitos previstos en el artículo 29 de la Ley Federal de Protección de Datos
            Personales en Posesión de los Particulares.
          </P>
          <P>
            Global Jet Set podrá negar el acceso para que Usted ejerza sus derechos ARCO en los
            siguientes supuestos:
          </P>
          <OrderedList>
            <ListItem>
              <P>
                Cuando Usted no sea el titular de los datos personales, o no pueda acreditar la
                representación del titular;
              </P>
            </ListItem>
            <ListItem>
              <P>Cuando sus datos personales no obren en la base de datos de Global Jet Set;</P>
            </ListItem>
            <ListItem>
              <P>Cuando se lesionen los derechos de un tercero;</P>
            </ListItem>
            <ListItem>
              <P>
                Cuando exista un impedimento legal o la resolución de una autoridad competente, que
                restrinja sus Derechos ARCO;
              </P>
            </ListItem>
            <ListItem>
              <P>
                Cuando la rectificación, cancelación u oposición haya sido previamente realizada.
              </P>
            </ListItem>
          </OrderedList>

          <P>
            El ejercicio de los Derechos ARCO será gratuito, pero si Usted reitera su solicitud en
            un periodo menor de doce meses, los costos serán de tres días de Salario Mínimo General
            Vigente en la Ciudad de México, más I.V.A., a menos que existan modificaciones
            sustanciales al Aviso de Privacidad que motiven nuevas Solicitudes de Ejercicio de
            Derechos ARCO. Usted deberá cubrir los gastos justificados de envío o el costo de
            reproducción en copias u otros formatos.
          </P>

          <P>
            <b>REQUISITOS PARA EJERCER LOS DERECHOS ARCO</b>
          </P>

          <OrderedList>
            <ListItem>
              <P>
                Su nombre, domicilio completo (Calle, número interior y/o exterior, colonia, código
                postal, ciudad y estado) conforme al formato “Solicitud de Ejercicio de Derechos
                ARCO” para poder comunicarle la respuesta a la solicitud ARCO;
              </P>
            </ListItem>
            <ListItem>
              <P>
                Los documentos que acrediten su identidad (copia de INE y/o pasaporte vigente) o en
                su caso, los documentos que acrediten su representación legal (copia de INE y/o
                pasaporte, cédula profesional o documento migratorio).
              </P>
            </ListItem>
            <ListItem>
              <P>
                Una descripción clara y precisa de los datos personales respecto de los cuales busca
                ejercer alguno de los Derechos ARCO;
              </P>
            </ListItem>
            <ListItem>
              <P>
                Cualquier documento o información que facilite la localización de sus datos
                personales y;
              </P>
            </ListItem>
            <ListItem>
              <P>
                En caso de solicitar una rectificación de datos, deberá de indicar también, las
                modificaciones a realizarse y aportar la documentación que sustente su petición
                (acta de nacimiento, comprobante de domicilio, etc.).
              </P>
            </ListItem>
          </OrderedList>

          <P>
            <b>REVOCACIÓN DE CONSENTIMIENTO</b>
          </P>

          <P>
            El titular podrá, en cualquier momento, tramitar la revocación de su consentimiento para
            el tratamiento de sus datos personales. La solicitud se deberá solicitar y presentar por
            medio digital al correo electrónico siguiente: alejandrohernandez@globaljetsetmexico.com
            siempre que exista causa legitima y su situación especifica así lo requiera o bien
            cuando no desee que sus datos personales se traten para fines específicos. No procederá
            el ejercicio del derecho de oposición cuando el tratamiento sea necesario para el
            cumplimiento de una obligación de Global Jet Set. La respuesta a tu solicitud se te
            comunicará en un plazo de veinte días hábiles, contados desde la fecha en que se
            recibió, pudiendo ampliarse a veinte días más en los caos en que así lo establezca la
            Ley; a efecto de que de resultar procedente, se lleven a cabo las medidas adoptadas para
            cumplir con tu solicitud, mismas que se llevarán a cabo dentro de los quince días
            hábiles siguientes a la fecha en que se comunique la respuesta. Te sugerimos conocer y
            analizar el contenido de la Ley Federal de Protección de Datos Personales en Posesión de
            los Particulares pues pueden generarse cambios normativos a los que estamos sujetos. Por
            otra parte, informamos a Usted que, sus datos personales no serán compartidos con
            ninguna autoridad, empresa, organización o personas distintas a nosotros y serán
            utilizados exclusivamente para los fines señalados.
          </P>

          <P>
            <b>NOTIFICACIÓN DE CAMBIOS AL AVISO DE PRIVACIDAD</b>
          </P>

          <P>
            Cualquier modificación al Aviso de Privacidad, se le hará de su conocimiento vía correo
            electrónico o bien, a través de la página de Global Jet Set (globaljetsetmexico.com).
            Este Aviso de Privacidad cumple con los requisitos establecidos en la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares vigente.
          </P>

          <P>
            <b>A T E N T A M E N T E</b>
          </P>

          <P>
            <b>GLOBAL JET SET MÉXICO</b>
          </P>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "index/hero1.jpg" }) {
      ...bannerSectionImage
    }
  }
`

export default IndexPage
